import { teacherEdit, uploadFile, userList } from "@/api/api";
import defaultImg from "@/static/addImg.png";
export default {
  name: "index",
  data() {
    return {
      user_type: localStorage.getItem('userType'),
      Info: {
        id: '',
        name: '',
        teachertor_id: '',
        id_no: '',
        img_card_z: '',
        img_card_f: '',
        img_education: '',
        img_teacher: '',
        check_msg: ''
      },
      is_info: '',
      default_img: defaultImg,
      teachertorList: [],
      rules: {
        id_no: [{
          required: true,
          message: "请输入教师身份证号",
          trigger: 'blur'
        }],
        name: [{
          required: true,
          message: "请输入教师名字",
          trigger: 'blur'
        }],
        img_card_z: [{
          required: true,
          message: "请上传身份证正面图片",
          trigger: 'blur'
        }],
        img_card_f: [{
          required: true,
          message: "请上传身份证反面图片",
          trigger: 'blur'
        }]
      }
    };
  },
  mounted() {
    let data = this.$root.useRoute.query;
    this.getUserList();
    if (data.Info) {
      this.Info = JSON.parse(data.Info);
    }
    this.is_info = data.is_info || '';
    if (data.isShenhe) {
      this.isShenhe = true;
    }
  },
  methods: {
    qxBtn() {
      this.$root.useRouter.go(-1);
    },
    getUserList() {
      userList({
        table_name: 'e2_teachertor'
      }).then(res => {
        this.teachertorList = res.data;
      });
    },
    beforeAvatarUpload(e, name) {
      let formData = new FormData();
      formData.append('file', e);
      uploadFile(formData).then(res => {
        if (res.success) {
          this.Info[name] = res.data;
        } else {
          this.$root.ElMessage.error(res.message);
        }
      });
    },
    subCheck(state) {
      this.Info.state = state;
      this.subData();
    },
    subData() {
      //提交
      this.$refs['Info'].validate(valid => {
        if (valid) {
          teacherEdit(this.Info).then(res => {
            this.$root.resultMsg(res);
          });
        }
      });
    }
  }
};